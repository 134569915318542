export const API_URL = (process.env.REACT_APP_API_URL + "api/") as string;

export const CRM_URL =
  "http://crmapp.branch.otpsrb.loc/crm/index.php?module=Opportunities&action=DetailView&record=";

export const HOMEPAGE_LINK_V3 =
  "https://www.otpbanka.rs/alt-otvaranje-paketa-racuna-i-usluga-za-partnere/";
export const HOMEPAGE_LINK_V4 =
  "https://www.otpbanka.rs/alt-vazne-informacije-o-specijalnoj-ponudi/";

export const KES_KREDITI_LINK_V1 =
  "https://www.otpbanka.rs/alt-kes-kredit-sa-varijabilnom-kamatom/";
export const KES_KREDITI_LINK_V2 =
  "https://www.otpbanka.rs/alt-kes-kredit-sa-fiksnom-kamatom/";
export const KES_KREDITI_LINK_V3 =
  "https://www.otpbanka.rs/alt-ref-kes-kredit-sa-varijabilnom-kamatom/";
export const KES_KREDITI_LINK_V4 =
  "https://www.otpbanka.rs/alt-ref-kes-kredit-sa-fiksnom-kamatom/";
export const KES_KREDITI_LINK_V5 =
  "https://www.otpbanka.rs/alt-evergrin-kes-kredit/";

export const STAMBENI_KREDITI_LINK_V1 =
  "https://www.otpbanka.rs/alt-stambeni-kredit-sa-kombinovanom-kamatom/";
export const STAMBENI_KREDITI_LINK_V2 =
  "https://www.otpbanka.rs/alt-stambeni-kredit-sa-varijabilnom-kamatom/";

export const GUIDE_FILENAME =
  "Kreditni vodič za gotovinske i stambene kredite OTP Banka - septembar 2022.pdf";
