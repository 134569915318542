import { TextField, TextFieldProps } from "@mui/material";
import { Controller } from "react-hook-form";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { ConnectForm } from "./ConnectForm";

export type NumericElementProps = Omit<
  NumericFormatProps<TextFieldProps>,
  "name"
> & {
  name: string;
  readOnly?: boolean;
};

export const NumericElement = ({
  type,
  name,
  readOnly = false,
  ...rest
}: NumericElementProps) => {
  return (
    <ConnectForm>
      {({ control }) => (
        <Controller
          name={name}
          control={control}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { invalid, error },
          }) => {
            return (
              <NumericFormat
                {...rest}
                name={name}
                size={rest.size ?? "small"}
                displayType="input"
                customInput={TextField}
                InputProps={{
                  readOnly,
                }}
                value={value || null}
                onValueChange={({ floatValue }) => {
                  onChange(floatValue ? floatValue : null);
                }}
                onBlur={onBlur}
                type="text"
                helperText={error ? error.message : rest.helperText}
                error={invalid}
                thousandSeparator={rest.thousandSeparator ?? "."}
                decimalSeparator={rest.decimalSeparator ?? ","}
                decimalScale={rest.decimalScale ?? 2}
                allowNegative={rest.allowNegative ?? false}
              />
            );
          }}
        />
      )}
    </ConnectForm>
  );
};
