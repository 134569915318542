import { Form, TextFieldElement, useForm } from "@/components/form";
import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as z from "zod";
import { useForgotPassword } from "../api/forgotPassword";

type FormValues = {
  username: string;
};

const defaultValues = {
  username: "",
};

export const ForgotPassword = () => {
  const { t } = useTranslation();

  const schema = z.object({
    username: z.string().min(1, t("form.validation.required")),
  });

  const forgotPasswordMutation = useForgotPassword();

  const onSubmit = (values: FormValues) => {
    forgotPasswordMutation.mutate(values);
  };

  const methods = useForm<FormValues, typeof schema>({
    options: { defaultValues },
    schema,
  });

  const { handleSubmit } = methods;

  return (
    <Form<FormValues> {...methods} onSubmit={handleSubmit(onSubmit)}>
      <TextFieldElement
        name="username"
        autoComplete="username"
        label={t("auth.username")}
        margin="normal"
        size="medium"
      />

      <Typography variant="caption" color="text.secondary" component="div">
        {t("auth.usernameHelperText")}
      </Typography>
      <LoadingButton
        loading={forgotPasswordMutation.isLoading}
        variant="contained"
        type="submit"
        fullWidth
        sx={{
          mt: 2,
        }}
      >
        {t("form.send")}
      </LoadingButton>
    </Form>
  );
};
