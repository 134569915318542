import { PaletteMode } from "@mui/material";

const storagePrefix = "partner_portal_";

const storage = {
  getMode: () => {
    return JSON.parse(
      window.localStorage.getItem(`${storagePrefix}mode`) as PaletteMode
    );
  },
  setMode: (mode: PaletteMode) => {
    window.localStorage.setItem(`${storagePrefix}mode`, JSON.stringify(mode));
  },
  clearMode: () => {
    window.localStorage.removeItem(`${storagePrefix}mode`);
  },
};

export default storage;
