import { formats } from "@/utils/formats";
import { TextField, TextFieldProps } from "@mui/material";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ConnectForm } from "./ConnectForm";

export declare type ParseableDate<TDate> =
  | string
  | number
  | Date
  | null
  | undefined
  | TDate;

export type DatePickerElementProps<TDate = unknown> = Omit<
  DatePickerProps<TDate, Date>,
  "value" | "onChange" | "renderInput"
> & {
  name: string;
  inputProps?: TextFieldProps;
  helperText?: TextFieldProps["helperText"];
};

export const DatePickerElement = ({
  name,
  inputProps,
  ...rest
}: DatePickerElementProps) => {
  const { i18n } = useTranslation();
  return (
    <ConnectForm>
      {({ control }) => (
        <Controller
          name={name}
          control={control}
          render={({
            field: { onChange, value },
            fieldState: { error, invalid },
          }) => (
            <DatePicker
              {...rest}
              inputFormat={formats.dateFormats[i18n.language]}
              value={value || null}
              onChange={onChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...inputProps}
                  value={value}
                  margin={inputProps?.margin ?? "dense"}
                  size={inputProps?.size ?? "small"}
                  fullWidth={inputProps?.fullWidth ?? true}
                  error={invalid}
                  helperText={
                    error
                      ? error.message
                      : inputProps?.helperText || rest.helperText
                  }
                />
              )}
            />
          )}
        />
      )}
    </ConnectForm>
  );
};
