import { Head, NotAuthorized, NotFound } from "@/components/misc";
import { RoleCheck } from "@/lib/authorization";
import { Navigate, Route, Routes } from "react-router-dom";
import { ChangePassword } from "../components/ChangePassword";
import { ForgotPassword } from "../components/ForgotPassword";
import { Layout } from "../components/Layout";
import { LayoutPrivate } from "../components/LayoutPrivate";
import { LoginForm } from "../components/LoginForm";
import { ROLES } from "../types";

export const PublicAuthRoutes = () => {
  return (
    <>
      <Head />
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <LoginForm />
            </Layout>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <Layout>
              <ForgotPassword />
            </Layout>
          }
        />
        <Route
          path="/change-password/:resetId"
          element={
            <Layout>
              <ChangePassword />
            </Layout>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};

export const PrivateAuthRoutes = () => {
  return (
    <>
      <Head />
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route
          path="/change-password"
          element={
            <RoleCheck
              roles={[ROLES.PARTNER, ROLES.AGENT]}
              forbiddenFallback={<NotAuthorized />}
            >
              <LayoutPrivate>
                <ChangePassword />
              </LayoutPrivate>
            </RoleCheck>
          }
        />
      </Routes>
    </>
  );
};
