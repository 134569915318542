import {
  AuthUser,
  FirstLoginResponse,
  getMe,
  getMeAuthorization,
  LoginCredentialsDTO,
  loginWithUsernameAndPassword,
  logout,
  refresh,
} from "@/features/auth";
import { ExtractFnReturnType } from "@/lib/react-query";
import { resetAllSlices } from "@/lib/zustand-create";
import { leadTableTracker } from "@/utils/leadTableTracker";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import * as React from "react";
import {
  QueryObserverResult,
  RefetchOptions,
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";

export let token = "";

interface AuthContextValue {
  user: AuthUser | undefined;
  login: UseMutateFunction<
    AuthUser | FirstLoginResponse,
    AxiosError,
    LoginCredentialsDTO
  >;
  logout: UseMutateFunction<void, AxiosError, void>;
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  refetchUser: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<AuthUser, AxiosError>>;
  error: AxiosError | null;
}

const AuthContext = React.createContext<AuthContextValue | null>(null);

const fetchUser = async () => {
  const [getMeResponse, getMeAuthorizationResponse] = await Promise.all([
    getMe(),
    getMeAuthorization(),
  ]);
  const user: AuthUser = {
    ...getMeResponse,
    permissions: getMeAuthorizationResponse,
  };
  return user;
};

const loadUser = async () => {
  const response = await refresh();
  token = response.token;
  const user = await fetchUser();
  return user;
};

const loginFn = async (data: LoginCredentialsDTO) => {
  const loginResponse = await loginWithUsernameAndPassword(data);
  if ("resetId" in loginResponse) {
    return loginResponse;
  } else {
    token = loginResponse.token;
    const user = await fetchUser();
    return user;
  }
};

type QueryFnType = typeof loadUser;

type ExtractedFnReturnType = ExtractFnReturnType<QueryFnType>;

type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const {
    data: user,
    error,
    refetch,
  } = useQuery<ExtractedFnReturnType, AxiosError>("authUser", loadUser, {
    suspense: true,
    onError: () => {
      queryClient.removeQueries({
        predicate: (query) => query.queryKey !== "authUser",
      });
      queryClient.setQueryData("authUser", undefined);
    },
  });

  const loginMutation = useMutation<
    AuthUser | FirstLoginResponse,
    AxiosError,
    LoginCredentialsDTO
  >({
    mutationFn: loginFn,
    onSuccess: (loginResponse) => {
      if (!("resetId" in loginResponse)) {
        queryClient.setQueryData("authUser", loginResponse);
        resetAllSlices();
        leadTableTracker.isInitialized = false;
      }
    },
    onError: (_error) => {
      const message = _error.response?.data?.message || _error.message;
      enqueueSnackbar(message, { variant: "error" });
    },
  });

  const logoutMutation = useMutation<void, AxiosError, void>({
    mutationFn: logout,
    onSettled: () => {
      token = "";
      queryClient.clear();
    },
  });

  const value = React.useMemo(
    () => ({
      user,
      error,
      refetchUser: refetch,
      login: loginMutation.mutate,
      isLoggingIn: loginMutation.isLoading,
      logout: logoutMutation.mutate,
      isLoggingOut: logoutMutation.isLoading,
    }),
    [
      user,
      error,
      refetch,
      loginMutation.mutate,
      loginMutation.isLoading,
      logoutMutation.mutate,
      logoutMutation.isLoading,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
