import { axios } from "@/lib/axios";
import { MutationConfig } from "@/lib/react-query";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";

type UpdateUserAuthorizationDTO = {
  data: {
    permission: boolean;
  };
  activityId: number;
  processId: number | undefined;
  userId: number | undefined;
};

const updateUserAuthorization = async ({
  userId,
  processId,
  activityId,
  data,
}: UpdateUserAuthorizationDTO): Promise<void> => {
  if (!userId || !processId) {
    return Promise.reject();
  }
  return await axios.post(
    `/authorization/${processId}/users/${userId}/activities/${activityId}`,
    data
  );
};

type UseUpdateUserAuthorizationOptions = {
  processId: number | undefined;
  userId: number | undefined;
  config?: MutationConfig<typeof updateUserAuthorization>;
};

export const useUpdateUserAuthorization = ({
  config,
  userId,
  processId,
}: UseUpdateUserAuthorizationOptions) => {
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  return useMutation({
    onError: (error) => {
      const message = error.response?.data?.message || error.message;
      enqueueSnackbar(message, { variant: "error" });
    },
    onSettled: () => {
      return queryClient.invalidateQueries([
        "authorization",
        processId,
        "users",
        userId,
      ]);
    },
    ...config,
    mutationFn: updateUserAuthorization,
  });
};
