import { ReactComponent as OtpLogo } from "@/assets/svg/otpLogo.svg";
import { ReactComponent as OtpLogoWhite } from "@/assets/svg/otpLogoWhite.svg";
import { Authorization, Process, useAuthorization } from "@/lib/authorization";
import { useMode } from "@/providers/mode";
import AdminPanelSettingsIconRounded from "@mui/icons-material/AdminPanelSettingsRounded";
import AssignmentIconRounded from "@mui/icons-material/AssignmentRounded";
import AssessmentIconRounded from "@mui/icons-material/AssessmentRounded";
import BadgeIconRounded from "@mui/icons-material/BadgeRounded";
import BarChartIconRounded from "@mui/icons-material/BarChartRounded";
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded";
import HandshakeRoundedIcon from "@mui/icons-material/HandshakeRounded";
import LocalOfferIconRounded from "@mui/icons-material/LocalOfferRounded";
import LocationOnIconRounded from "@mui/icons-material/LocationOnRounded";
import PercentIconRounded from "@mui/icons-material/PercentRounded";
import ThumbUpIconRounded from "@mui/icons-material/ThumbUpRounded";
import ArchiveRoundedIcon from "@mui/icons-material/ArchiveRounded";
import {
  Box,
  Divider,
  Drawer,
  DrawerProps,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

type ChildCategories = {
  title: string;
  icon: JSX.Element;
  processes: Process[];
};

type Categories = {
  title?: string;
  id: number;
  children: ChildCategories[];
};

const categories: Categories[] = [
  {
    id: 1,
    children: [
      {
        title: "users",
        icon: <AdminPanelSettingsIconRounded color="info" />,
        processes: ["Users", "Authorization"],
      },
    ],
  },
  {
    id: 2,
    title: "portal",
    children: [
      {
        title: "leads",
        icon: <ThumbUpIconRounded color="primary" />,
        processes: ["Leads"],
      },
      {
        title: "calculations",
        icon: <BarChartIconRounded color="primary" />,
        processes: ["Calculations"],
      },
      {
        title: "reports",
        icon: <AssignmentIconRounded color="primary" />,
        processes: ["Reports"],
      },
      {
        title: "reports/portfolio",
        icon: <AssessmentIconRounded color="primary" />,
        processes: ["Reports"],
      },
      {
        title: "tariffs",
        icon: <PercentIconRounded color="primary" />,
        processes: ["Tariffs"],
      },
    ],
  },
  {
    id: 3,
    title: "codebooks",
    children: [
      {
        title: "branches",
        icon: <LocationOnIconRounded color="primary" />,
        processes: ["Branches"],
      },
      {
        title: "products",
        icon: <LocalOfferIconRounded color="primary" />,
        processes: ["Products"],
      },
      {
        title: "partners",
        icon: <HandshakeRoundedIcon color="primary" />,
        processes: ["Partners"],
      },
      {
        title: "agents",
        icon: <BusinessCenterRoundedIcon color="primary" />,
        processes: ["Agents"],
      },
      {
        title: "employees",
        icon: <BadgeIconRounded color="primary" />,
        processes: ["Employees"],
      },
      {
        title: "archive",
        icon: <ArchiveRoundedIcon color="primary" />,
        processes: ["Archive"],
      },
    ],
  },
];

export const Navigator = (props: DrawerProps) => {
  const { mode } = useMode();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const firstPartOfPathname = pathname.split("/")[1];

  const { checkAccess } = useAuthorization();

  const { t } = useTranslation();

  const changeSelectedItemAndRoute = (selection: string) => {
    navigate(`/${selection}`);
  };

  return (
    <Drawer variant="temporary" {...props}>
      <List disablePadding>
        <ListItem
          sx={{ py: 1.5, px: 3, cursor: "pointer" }}
          onClick={() => changeSelectedItemAndRoute("home")}
        >
          {mode === "light" ? <OtpLogo /> : <OtpLogoWhite />}
        </ListItem>
        <Divider />
        {categories.map(({ title, children, id }, index) =>
          children.some(({ processes }) => checkAccess(["Get"], processes)) ? (
            <React.Fragment key={id}>
              <Box>
                {title ? (
                  <ListItem sx={{ py: 1, px: 2 }}>
                    <ListItemText>{t(`navigation.${title}`)}</ListItemText>
                  </ListItem>
                ) : null}
                {children.map(({ title: childTitle, icon, processes }) => (
                  <Authorization
                    processes={processes}
                    activities={["Get"]}
                    key={childTitle}
                  >
                    <ListItem disablePadding>
                      <ListItemButton
                        selected={childTitle === firstPartOfPathname}
                        sx={{ py: 1.5, px: 3 }}
                        onClick={() => changeSelectedItemAndRoute(childTitle)}
                      >
                        <ListItemIcon>{icon}</ListItemIcon>
                        <ListItemText>
                          {t(`navigation.${childTitle}`)}
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </Authorization>
                ))}
              </Box>
              {index < categories.length - 1 ? <Divider /> : null}
            </React.Fragment>
          ) : null
        )}
      </List>
    </Drawer>
  );
};
