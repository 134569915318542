import { MainLayout } from "@/components/layout";
import { Fallback, NotFound } from "@/components/misc";
import { lazyImport } from "@/utils/lazyImport";
import * as React from "react";
import { Navigate, Outlet } from "react-router-dom";

const { ProductRoutes } = lazyImport(
  () => import("@/features/products"),
  "ProductRoutes"
);

const { HomeRoutes } = lazyImport(
  () => import("@/features/home"),
  "HomeRoutes"
);

const { UserRoutes } = lazyImport(
  () => import("@/features/users"),
  "UserRoutes"
);

const { PartnerRoutes } = lazyImport(
  () => import("@/features/partners"),
  "PartnerRoutes"
);

const { LeadRoutes } = lazyImport(
  () => import("@/features/leads"),
  "LeadRoutes"
);

const { CalculationRoutes } = lazyImport(
  () => import("@/features/calculations"),
  "CalculationRoutes"
);

const { BranchRoutes } = lazyImport(
  () => import("@/features/branches"),
  "BranchRoutes"
);

const { ReportRoutes } = lazyImport(
  () => import("@/features/reports"),
  "ReportRoutes"
);

const { EmployeeRoutes } = lazyImport(
  () => import("@/features/employees"),
  "EmployeeRoutes"
);

const { TariffRoutes } = lazyImport(
  () => import("@/features/tariffs"),
  "TariffRoutes"
);

const { AgentRoutes } = lazyImport(
  () => import("@/features/agents"),
  "AgentRoutes"
);

const { PrivateAuthRoutes } = lazyImport(
  () => import("@/features/auth"),
  "PrivateAuthRoutes"
);
const { ReportPortfolioRoutes } = lazyImport(
  () => import("@/features/reportsPortfolio"),
  "ReportPortfolioRoutes"
);

const { ArchiveRoutes } = lazyImport(
  () => import("@/features/archive"),
  "ArchiveRoutes"
);

const App = () => {
  return (
    <MainLayout>
      <React.Suspense fallback={<Fallback />}>
        <Outlet />
      </React.Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/*",
        element: <NotFound />,
      },
      {
        path: "/",
        element: <Navigate to="/home" />,
      },
      {
        path: "/products/*",
        element: <ProductRoutes />,
      },
      {
        path: "/home/*",
        element: <HomeRoutes />,
      },
      {
        path: "/leads/*",
        element: <LeadRoutes />,
      },

      {
        path: "/users/*",
        element: <UserRoutes />,
      },
      {
        path: "/partners/*",
        element: <PartnerRoutes />,
      },
      {
        path: "/agents/*",
        element: <AgentRoutes />,
      },
      {
        path: "/calculations/*",
        element: <CalculationRoutes />,
      },
      {
        path: "/branches/*",
        element: <BranchRoutes />,
      },
      {
        path: "/reports/*",
        element: <ReportRoutes />,
      },
      {
        path: "/employees/*",
        element: <EmployeeRoutes />,
      },
      {
        path: "/tariffs/*",
        element: <TariffRoutes />,
      },

      {
        path: "/auth/*",
        element: <PrivateAuthRoutes />,
      },
      {
        path: "/reports/portfolio/*",
        element: <ReportPortfolioRoutes />,
      },
      {
        path: "/archive/*",
        element: <ArchiveRoutes />,
      },
    ],
  },
];
