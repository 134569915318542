import { MenuItem, TextField, TextFieldProps } from "@mui/material";
import { createElement } from "react";
import { Controller, Path } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { ConnectForm } from "./ConnectForm";

export type SelectElementProps<T> = Omit<
  TextFieldProps,
  "name" | "type" | "onChange"
> & {
  name: Path<T>;
  options?: { id: string | number; label: string | number }[] | any[];
  valueKey?: string;
  labelKey?: string;
  type?: "string" | "number";
  objectOnChange?: boolean;
  onChange?: (value: any) => void;
};

export const SelectElement = <TFieldValues extends FieldValues>({
  name,
  required,
  valueKey = "id",
  labelKey = "label",
  options = [],
  type,
  objectOnChange,
  ...rest
}: SelectElementProps<TFieldValues>) => {
  const isNativeSelect = !!rest.SelectProps?.native;
  const ChildComponent = isNativeSelect ? "option" : MenuItem;

  return (
    <ConnectForm>
      {({ control }) => (
        <Controller
          name={name}
          control={control}
          render={({
            field: { onBlur, onChange, value },
            fieldState: { invalid, error },
          }) => {
            // handle shrink on number input fields
            if (type === "number" && typeof value !== "undefined") {
              rest.InputLabelProps = rest.InputLabelProps || {};
              rest.InputLabelProps.shrink = true;
            }
            if (typeof value === "object" && value !== null) {
              value = value[valueKey]; // if value is object get key
            }
            return (
              <TextField
                {...rest}
                size={rest.size ?? "small"}
                name={name}
                value={value}
                onBlur={onBlur}
                onChange={(event) => {
                  let item: number | string = event.target.value;
                  if (type === "number") {
                    item = Number(item);
                  }
                  onChange(item);
                  if (typeof rest.onChange === "function") {
                    if (objectOnChange) {
                      item = options.find((i) => i[valueKey] === item);
                    }
                    rest.onChange(item);
                  }
                }}
                select
                required={required}
                error={invalid}
                helperText={error ? error.message : rest.helperText}
              >
                {isNativeSelect && <option />}
                {options.map((item: any) =>
                  createElement(
                    ChildComponent,
                    {
                      key: `${name}_${item[valueKey]}`,
                      value: item[valueKey],
                    },
                    item[labelKey]
                  )
                )}
              </TextField>
            );
          }}
        />
      )}
    </ConnectForm>
  );
};
