import { Card, CardContent, CardHeader, Container } from "@mui/material";
import { useTranslation } from "react-i18next";

type LayoutProps = {
  children?: React.ReactNode;
};

export const LayoutPrivate = ({ children }: LayoutProps) => {
  const { t } = useTranslation();
  return (
    <Container maxWidth="sm" disableGutters>
      <Card>
        <CardHeader title={t("auth.changePassword")} />
        <CardContent>{children}</CardContent>
      </Card>
    </Container>
  );
};
