import { RolesValues } from "@/features/auth";
import { useAuth } from "@/providers/auth";
import * as React from "react";

export type Activity =
  | "Get"
  | "Create"
  | "Update"
  | "Delete"
  | "Verify"
  | "Meeting"
  | "Exclude"
  | "Upload"
  | "Download"
  | "Invalidate"
  | "Forward"
  | "Extend";

export type Process =
  | "Users"
  | "Authorization"
  | "Leads"
  | "Calculations"
  | "Reports"
  | "Tariffs"
  | "Branches"
  | "Products"
  | "Partners"
  | "Agents"
  | "Employees"
  | "Documentation"
  | "Archive";

export const useAuthorization = () => {
  const { user } = useAuth();

  if (!user) {
    throw Error("User does not exist!");
  }

  const { permissions, roles } = user;

  const checkAccess = React.useCallback(
    (activities: Activity[], processes: Process[]) =>
      activities.some((activity) =>
        processes.some(
          (process) =>
            permissions[process] && permissions[process].includes(activity)
        )
      ),
    [permissions]
  );

  const checkRoles = React.useCallback(
    (rolesToCheck: RolesValues[]) =>
      rolesToCheck.some((role) => roles.includes(role)),
    [roles]
  );

  return { checkAccess, checkRoles };
};

type AuthorizationProps = {
  children: React.ReactNode;
  activities: Activity[];
  processes: Process[];
  forbiddenFallback?: React.ReactNode;
};

export const Authorization = ({
  children,
  activities,
  processes,
  forbiddenFallback = null,
}: AuthorizationProps) => {
  const { checkAccess } = useAuthorization();

  const canAccess = checkAccess(activities, processes);

  return <>{canAccess ? children : forbiddenFallback}</>;
};

type RoleCheckProps = {
  children: React.ReactNode;
  roles: RolesValues[];
  forbiddenFallback?: React.ReactNode;
};

export const RoleCheck = ({
  children,
  roles,
  forbiddenFallback = null,
}: RoleCheckProps) => {
  const { checkRoles } = useAuthorization();

  const canAccess = checkRoles(roles);

  return <>{canAccess ? children : forbiddenFallback}</>;
};
