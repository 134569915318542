import { useQuery } from "react-query";
import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { AxiosError } from "axios";
import { UserAuthorizationResponse } from "../types";

const getUserAuthorization = async ({
  processId,
  userId,
}: {
  processId: number | undefined;
  userId: number | undefined;
}): Promise<UserAuthorizationResponse[]> => {
  return await axios.get(`/authorization/${processId}/users/${userId}`);
};

type QueryFnType = typeof getUserAuthorization;

type UseAuthorizationOptions = {
  processId: number | undefined;
  userId: number | undefined;
  config?: QueryConfig<QueryFnType>;
};

export const useUserAuthorization = ({
  config,
  processId,
  userId,
}: UseAuthorizationOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>, AxiosError>({
    ...config,
    queryKey: ["authorization", processId, "users", userId],
    queryFn: () => getUserAuthorization({ processId, userId }),
  });
};
