import { FC } from "react";
import {
  LocalizationProvider,
  LocalizationProviderProps,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enUS, srLatn } from "date-fns/locale";
import { useTranslation } from "react-i18next";

export type DateFnsProviderProps = FC<
  Omit<LocalizationProviderProps, "dateAdapter">
>;

const adapterLocaleMap: Record<string, Locale> = {
  "en-US": enUS,
  "sr-Latn": srLatn,
};

export const DateFnsProvider: DateFnsProviderProps = ({
  children,
  ...rest
}) => {
  const { i18n } = useTranslation();

  return (
    <LocalizationProvider
      {...rest}
      dateAdapter={AdapterDateFns}
      adapterLocale={adapterLocaleMap[i18n.language]}
    >
      {children}
    </LocalizationProvider>
  );
};
