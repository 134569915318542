import { useQuery } from "react-query";
import { axios } from "@/lib/axios";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { AxiosError } from "axios";
import { AuthorizationResponse } from "../types";

const getAuthorization = async (): Promise<AuthorizationResponse[]> => {
  return await axios.get("/authorization");
};

type QueryFnType = typeof getAuthorization;

type UseAuthorizationOptions = {
  userId: number | undefined;
  config?: QueryConfig<QueryFnType>;
};

export const useAuthorization = ({
  config,
  userId,
}: UseAuthorizationOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>, AxiosError>({
    ...config,
    queryKey: ["authorization"],
    queryFn: () => getAuthorization(),
    enabled: !!userId,
  });
};
