const dateFormats: Record<string, string> = {
  "en-US": "MM/dd/yyyy",
  "sr-Latn": "dd.MM.yyyy.",
};

const dateTimeFormats: Record<string, string> = {
  "en-US": "MM/dd/yyyy hh:mm",
  "sr-Latn": "dd.MM.yyyy. HH:mm",
};

export const formats = {
  dateFormats,
  dateTimeFormats,
};
