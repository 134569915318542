import { ROLES } from "@/features/auth";
import { RoleCheck } from "@/lib/authorization";
import { useAuth } from "@/providers/auth";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownCircleRounded";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const MenuDropDown = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { user, logout } = useAuth();

  const handleChangePassword = () => {
    setAnchorEl(null);
    navigate("auth/change-password");
  };

  const handleLogout = () => {
    setAnchorEl(null);
    logout();
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title={<>{t("dropdownNavigation.tooltip")}</>}>
          <IconButton
            onClick={handleClick}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            color="inherit"
            size="large"
          >
            <ArrowDropDownRoundedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        transitionDuration={2}
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            width: 270,
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,

              mr: 1,
            },
            "&:before": {
              content: "''",
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <ListItem divider>
          <ListItemIcon>
            <Avatar
              sx={{
                bgcolor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.primary.light
                    : theme.palette.primary.dark,
              }}
            />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            {user?.name}
          </Typography>
        </ListItem>
        <ListItem dense>
          <Typography variant="body2" noWrap fontWeight={600}>
            {t("dropdownNavigation.name")}
          </Typography>
        </ListItem>
        {user && (
          <ListItem dense>
            <Tooltip title={user.details.name} placement="top-start">
              <Typography variant="body2" noWrap>
                {user.details.name}
              </Typography>
            </Tooltip>
          </ListItem>
        )}
        {user?.details.partner ? (
          <ListItem dense>
            <Tooltip title={user.details.partner} placement="top-start">
              <Typography variant="body2" noWrap>
                {user.details.partner}
              </Typography>
            </Tooltip>
          </ListItem>
        ) : null}
        {user?.details.branches.length ? (
          <ListItem dense>
            <Typography variant="body2" noWrap fontWeight={600}>
              {t("dropdownNavigation.branches")}
            </Typography>
          </ListItem>
        ) : null}
        {user?.details.branches.map((branch) => (
          <ListItem key={branch} dense>
            <Tooltip title={branch} placement="top-start">
              <Typography variant="body2" noWrap pl={1}>
                {branch}
              </Typography>
            </Tooltip>
          </ListItem>
        ))}
        <RoleCheck roles={[ROLES.PARTNER, ROLES.AGENT]}>
          <MenuItem onClick={handleChangePassword}>
            <ListItemText>{t("auth.changePassword")}</ListItemText>
          </MenuItem>
        </RoleCheck>
        <Box component="li" sx={{ px: 1.5, mt: 1 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleLogout}
          >
            {t("dropdownNavigation.logout")}
          </Button>
        </Box>
      </Menu>
    </>
  );
};
