import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import {
  AppBar,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { LanguageDropDown } from "./LanguageDropDown";
import { MenuDropDown } from "./MenuDropDown";
import { ModeToggleDropDown } from "./ModeToggleDropDown";

type HideOnScrollProps = {
  children: React.ReactElement;
};

const HideOnScroll = ({ children }: HideOnScrollProps) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

type HeaderProps = {
  onDrawerToggle: () => void;
};

export const Header = (props: HeaderProps) => {
  const { onDrawerToggle } = props;

  const { pathname } = useLocation();

  const { t, i18n } = useTranslation();

  const title = "navigation." + pathname.substring(1).split("/")[0];

  return (
    <>
      <HideOnScroll>
        <AppBar
          position="sticky"
          color="primary"
          elevation={0}
          sx={{
            py: 1,
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={onDrawerToggle}
              edge="start"
              size="medium"
            >
              <MenuRoundedIcon />
            </IconButton>
            <Typography
              fontSize={{ xs: 22, sm: 24 }}
              component="h1"
              flexGrow={1}
            >
              {i18n.exists(title) ? t(title) : ""}
            </Typography>
            <ModeToggleDropDown />
            <LanguageDropDown />
            <MenuDropDown />
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </>
  );
};
