import { PaletteMode, ThemeOptions } from "@mui/material";
import { grey } from "@mui/material/colors";

declare module "@mui/material" {
  interface Theme {
    lead: {
      yellow: {
        main: string;
        hover: string;
      };
      orange: {
        main: string;
        hover: string;
      };
      red: {
        main: string;
        hover: string;
      };
    };
  }
  interface ThemeOptions {
    lead?: {
      yellow?: {
        main?: string;
        hover?: string;
      };
      orange?: {
        main?: string;
        hover?: string;
      };
      red?: {
        main?: string;
        hover?: string;
      };
    };
  }
}

const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
  lead: {
    yellow: {
      main: mode === "light" ? "#ffff33" : "#cccc00",
      hover: mode === "light" ? "#ffff00" : "#999900",
    },
    orange: {
      main: "#ffa500",
      hover: "#ff8c00",
    },
    red: {
      main: "#FF6347",
      hover: "#FF0000",
    },
  },
  palette: {
    mode,
    primary: {
      main: "#52ae30",
      light: "#74be59",
      dark: "#397921",
      contrastText: "#fff",
    },
    secondary: {
      main: "#006648",
    },
    background: {
      default: mode === "light" ? grey[50] : grey[900],
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiPaper: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiCard: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiTable: {
      defaultProps: {
        size: "small",
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiIcon: {
      defaultProps: {
        fontSize: "inherit",
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        fontSize: "inherit",
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: "dense",
        autoFocus: false,
        fullWidth: true,
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: 500,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: 16,
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: "small",
      },
    },
    MuiDialog: {
      defaultProps: {
        transitionDuration: 0,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

export default getDesignTokens;
