import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  FormHelperText,
} from "@mui/material";
import { Controller, Path } from "react-hook-form";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { ConnectForm } from "./ConnectForm";

export type CheckboxElementProps<T> = Omit<CheckboxProps, "name"> & {
  name: Path<T>;
  label?: FormControlLabelProps["label"];
  helperText?: string;
};

export const CheckboxElement = <TFieldValues extends FieldValues>({
  name,
  label,
  helperText,
  ...rest
}: CheckboxElementProps<TFieldValues>) => {
  return (
    <ConnectForm>
      {({ control }) => (
        <Controller
          name={name}
          control={control}
          render={({
            field: { value, onChange },
            fieldState: { invalid, error },
          }) => {
            return (
              <FormControl error={invalid}>
                <FormGroup row>
                  <FormControlLabel
                    label={label || ""}
                    control={
                      <Checkbox
                        {...rest}
                        size="medium"
                        color={rest.color || "primary"}
                        sx={{
                          ...rest.sx,
                          color: invalid ? "error.main" : undefined,
                        }}
                        checked={value}
                        value={value}
                        onChange={onChange}
                      />
                    }
                  />
                </FormGroup>
                <FormHelperText error={invalid}>
                  {error ? error.message : helperText}
                </FormHelperText>
              </FormControl>
            );
          }}
        />
      )}
    </ConnectForm>
  );
};
