import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { TreeItem, TreeView } from "@mui/lab";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import * as React from "react";
import { useController, useFormContext } from "react-hook-form";
import { Spinner } from "../spinner";

type TreeItemElementProps = {
  node: Record<string, any>;
  nodeIdKey: string;
  nodeLabelKey: string;
  value: any;
  onChange: (...event: any[]) => void;
  invalid: boolean;
  multiSelect?: boolean;
};

const TreeItemElement = ({
  node,
  nodeIdKey,
  nodeLabelKey,
  value,
  onChange,
  multiSelect = true,
  invalid,
}: TreeItemElementProps) => {
  if (!node) {
    return null;
  }
  return (
    <TreeItem
      nodeId={node[nodeIdKey].toString()}
      label={
        <FormControlLabel
          control={
            <Checkbox
              size="medium"
              sx={{
                color: (theme) =>
                  invalid ? theme.palette.error.main : undefined,
              }}
              checked={
                Array.isArray(value)
                  ? !!value.find((nodeId: any) => nodeId === node[nodeIdKey])
                  : value === node[nodeIdKey]
              }
              onChange={(event) => {
                if (multiSelect) {
                  const array = event.currentTarget.checked
                    ? value
                      ? value.concat(node[nodeIdKey])
                      : [node[nodeIdKey]]
                    : value.filter((nodeId: any) => nodeId !== node[nodeIdKey]);
                  onChange(array);
                } else {
                  onChange(node[nodeIdKey]);
                }
              }}
              onClick={(e) => e.stopPropagation()}
            />
          }
          label={node[nodeLabelKey]}
        />
      }
    >
      {Array.isArray(node.children)
        ? node.children.map((nextNode: Record<string, any>) => (
            <TreeItemElement
              key={nextNode[nodeIdKey]}
              node={nextNode}
              nodeIdKey={nodeIdKey}
              nodeLabelKey={nodeLabelKey}
              value={value}
              onChange={onChange}
              multiSelect={multiSelect}
              invalid={invalid}
            />
          ))
        : null}
    </TreeItem>
  );
};

type TreeViewElementProps = {
  name: string;
  options: any;
  optionIdKey: string;
  optionLabelKey: string;
  helperText?: string;
  disabled?: boolean;
  label?: string;
  defaultExpanded?: string[];
  structure?: "flat" | "hierarchical";
  optionParentKey?: string;
  multiSelect?: boolean;
  loading?: boolean;
};

export const TreeViewElement = ({
  name,
  options,
  optionIdKey = "id",
  optionLabelKey,
  helperText = "",
  disabled = false,
  label = "",
  defaultExpanded = ["1"],
  structure = "hierarchical",
  optionParentKey = "parentId",
  multiSelect = true,
  loading = false,
}: TreeViewElementProps) => {
  const { control } = useFormContext();

  const treefy = React.useCallback(
    (list: any[]) => {
      const map = list.reduce((m, li, i) => {
        m[li[optionIdKey]] = i;
        li.children = [];
        return m;
      }, {});
      return list.reduce((root, li) => {
        const arr =
          li[optionParentKey] !== -1
            ? list[map[li[optionParentKey]]].children
            : root;
        arr.push(li);
        return root;
      }, []);
    },
    [optionIdKey, optionParentKey]
  );

  const {
    field: { value, onChange },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
  });

  const optionsToMap = structure === "flat" ? treefy(options) : options;

  return (
    <FormControl
      error={invalid}
      component="fieldset"
      variant="standard"
      fullWidth
      sx={{ marginTop: 1 }}
      disabled={disabled}
    >
      {label && <FormLabel error={invalid}>{label}</FormLabel>}
      {loading ? (
        <Spinner color="primary" size={40} />
      ) : (
        <TreeView
          defaultCollapseIcon={<ExpandMoreRoundedIcon />}
          defaultExpandIcon={<ChevronRightRoundedIcon />}
          defaultExpanded={defaultExpanded}
        >
          {optionsToMap.map((option: Record<string, any>) => (
            <TreeItemElement
              key={option[optionIdKey]}
              node={option}
              nodeIdKey={optionIdKey}
              nodeLabelKey={optionLabelKey}
              value={value}
              onChange={onChange}
              multiSelect={multiSelect}
              invalid={invalid}
            />
          ))}
        </TreeView>
      )}
      <FormHelperText>{error ? error.message : helperText}</FormHelperText>
    </FormControl>
  );
};
