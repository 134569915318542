import { zodResolver } from "@hookform/resolvers/zod";
import {
  useForm as _useForm,
  UseFormProps,
  FieldValues,
} from "react-hook-form";
import * as z from "zod";

type UseFormOptions<
  TFormValues extends FieldValues = FieldValues,
  Schema extends z.ZodType<unknown, z.ZodTypeDef, unknown> = z.ZodType<
    unknown,
    z.ZodTypeDef,
    unknown
  >
> = {
  options?: UseFormProps<TFormValues>;
  schema?: Schema;
};

export const useForm = <
  TFormValues extends FieldValues = FieldValues,
  Schema extends z.ZodType<unknown, z.ZodTypeDef, unknown> = z.ZodType<
    unknown,
    z.ZodTypeDef,
    unknown
  >
>({
  options,
  schema,
}: UseFormOptions<TFormValues, Schema>) => {
  const methods = _useForm<TFormValues>({
    ...options,
    resolver: schema && zodResolver(schema),
  });

  return { ...methods };
};
