import * as React from "react";
import * as z from "zod";
import { useTranslation } from "react-i18next";

export const useErrorMaps = () => {
  const { t } = useTranslation();

  const dateErrorMap: z.ZodErrorMap = React.useCallback(
    (error, ctx) => {
      if (error.code === z.ZodIssueCode.invalid_date) {
        return { message: t("form.validation.invalidDate") };
      }

      if (error.code === z.ZodIssueCode.invalid_type) {
        return { message: t("form.validation.required") };
      }

      return { message: ctx.defaultError };
    },
    [t]
  );

  return { dateErrorMap };
};
