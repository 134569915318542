import { useConfirm } from "material-ui-confirm";
import * as React from "react";
import { DeepMap, DeepPartial, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";

type UseWarnWhenUnsavedChangesOptions<TFormValues extends FieldValues> = {
  dirtyFields: DeepMap<DeepPartial<TFormValues>, boolean>;
  translationKey: string;
  disabled?: boolean;
};

export const UseWarnWhenUnsavedChanges = <TFormValues extends FieldValues>({
  dirtyFields,
  translationKey,
  disabled = false,
}: UseWarnWhenUnsavedChangesOptions<TFormValues>) => {
  const { t } = useTranslation();

  const confirm = useConfirm();

  const warnWhenUnsavedChanges =
    React.useCallback(async (): Promise<boolean> => {
      if (disabled) {
        return false;
      }
      const dirtyFieldsKeys = Object.keys(dirtyFields);
      if (!dirtyFieldsKeys.length) {
        return true;
      } else {
        try {
          await confirm({
            title: t("form.dialogs.closeFormTitle"),
            description: `${t(
              "form.dialogs.closeFormDescription"
            )}: ${dirtyFieldsKeys
              .map((field) => t(`${translationKey}.columns.${field}`))
              .join(", ")}`,
          });
          return true;
        } catch {
          return false;
        }
      }
    }, [confirm, dirtyFields, disabled, translationKey, t]);

  return { warnWhenUnsavedChanges };
};
