import * as React from "react";
import { TextFieldElement, TextFieldElementProps } from "./TextFieldElement";
import { IconButton, IconButtonProps, InputAdornment } from "@mui/material";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";

export type PasswordElementProps = TextFieldElementProps & {
  iconColor?: IconButtonProps["color"];
};

export const PasswordElement = ({
  iconColor,
  ...rest
}: PasswordElementProps) => {
  const [password, setPassword] = React.useState<boolean>(true);
  return (
    <TextFieldElement
      {...rest}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) =>
                e.preventDefault()
              }
              onClick={() => setPassword(!password)}
              tabIndex={-1}
              color={iconColor ?? "default"}
              size="medium"
              disabled={rest.disabled}
            >
              {password ? (
                <VisibilityRoundedIcon />
              ) : (
                <VisibilityOffRoundedIcon />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      type={password ? "password" : "text"}
    />
  );
};
