import { API_URL } from "@/config";
import { token } from "@/providers/auth";
import Axios, { AxiosRequestConfig } from "axios";
import i18next from "i18next";
import { queryClient } from "./react-query";

function authRequestInterceptor(config: AxiosRequestConfig) {
  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: "Bearer " + token,
    };
  }
  config.headers = {
    ...config.headers,
    "Accept-Language": i18next.language,
  };
  return config;
}

export const axios = Axios.create({
  baseURL: API_URL,
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (response.config.getFullResponse === true) {
      return response;
    }
    return response.data;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401) {
      if (originalRequest.url === "/authentication") {
        return Promise.reject(error);
      }

      if (originalRequest.url === "/users/login") {
        return Promise.reject(error);
      }

      if (!originalRequest._retry) {
        originalRequest._retry = true;
        await queryClient.invalidateQueries("authUser");
        return axios(originalRequest);
      }
    }

    if (error.response.status === 403) {
      await queryClient.invalidateQueries("authUser");
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);
