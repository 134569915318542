import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const ScreenSizeNotSupported = () => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Typography variant="h4" fontStyle="italic">
        {t("screenSizeNotSupported")}
      </Typography>
    </Box>
  );
};
