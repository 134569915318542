import { Box } from "@mui/material";
import { Spinner } from "../spinner";

export const Fallback = () => (
  <Box height="100vh">
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Spinner />
    </Box>
  </Box>
);
