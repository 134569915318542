import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { useTranslation } from "react-i18next";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";

export const SubmitButton = (props: LoadingButtonProps) => {
  const { t } = useTranslation();

  return (
    <LoadingButton {...props} variant="contained" endIcon={<SaveRoundedIcon />}>
      {t("form.submit")}
    </LoadingButton>
  );
};
