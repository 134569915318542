import {
  Form,
  PasswordElement,
  TextFieldElement,
  useForm,
} from "@/components/form";
import { useAuth } from "@/providers/auth";
import { LoadingButton } from "@mui/lab";
import { Box, Link as MuiLink } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import * as z from "zod";
import { LoginCredentialsDTO } from "../api/login";

const defaultValues = {
  username: "",
  password: "",
};

export const LoginForm = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const schema = z.object({
    username: z.string().min(1, t("form.validation.required")),
    password: z.string().min(1, t("form.validation.required")),
  });

  const { login, isLoggingIn } = useAuth();

  const onSubmit = (values: LoginCredentialsDTO) => {
    login(values, {
      onSuccess: (loginResponse) => {
        if ("resetId" in loginResponse) {
          navigate(`change-password/${loginResponse.resetId}`, {
            state: { firstSignIn: true },
          });
        }
      },
    });
  };

  const methods = useForm<LoginCredentialsDTO, typeof schema>({
    options: { defaultValues },
    schema,
  });

  const { handleSubmit } = methods;

  return (
    <Form<LoginCredentialsDTO> {...methods} onSubmit={handleSubmit(onSubmit)}>
      <TextFieldElement
        name="username"
        autoComplete="username"
        label={t("auth.username")}
        margin="normal"
        size="medium"
      />
      <PasswordElement
        name="password"
        autoComplete="current-password"
        label={t("auth.password")}
        margin="normal"
        size="medium"
      />
      <Box display="flex" justifyContent="right" alignItems="right">
        <MuiLink component={Link} to="/forgot-password" underline="hover">
          {t("auth.forgotPassword")}
        </MuiLink>
      </Box>
      <LoadingButton
        loading={isLoggingIn}
        type="submit"
        variant="contained"
        fullWidth
        sx={{ mt: 2 }}
      >
        {t("auth.loginButton")}
      </LoadingButton>
    </Form>
  );
};
