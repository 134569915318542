import { useMode } from "@/providers/mode";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

export const ModeToggleDropDown = () => {
  const { toggleMode, mode } = useMode();

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Tooltip title={<>{t("dropdownNavigation.mode")}</>}>
        <IconButton onClick={toggleMode} color="inherit" size="large">
          {mode === "dark" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
        </IconButton>
      </Tooltip>
    </Box>
  );
};
