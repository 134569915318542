import { useScrollToTop } from "@/hooks/useScrollToTop";
import { useAuth } from "@/providers/auth";
import { useRoutes } from "react-router-dom";
import { protectedRoutes } from "./protected";
import { publicRoutes } from "./public";

export const AppRoutes = () => {
  useScrollToTop();

  const { user } = useAuth();

  const routes = user ? protectedRoutes : publicRoutes;

  const element = useRoutes([...routes]);

  return <>{element}</>;
};
