import { lazyImport } from "@/utils/lazyImport";

const { PublicAuthRoutes } = lazyImport(
  () => import("@/features/auth"),
  "PublicAuthRoutes"
);

export const publicRoutes = [
  {
    path: "*",
    element: <PublicAuthRoutes />,
  },
];
