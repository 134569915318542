import _create, { StateCreator, StoreApi, UseBoundStore } from "zustand";

const resetters: (() => void)[] = [];

export const create = <TState>(
  createState: StateCreator<TState> | StoreApi<TState>
): UseBoundStore<StoreApi<TState>> => {
  // We need to use "createState as never" to support "StateCreator<TState>"
  // and "StoreApi<TState>" at the same time.
  // We also need to re-type slice to "UseBoundStore<StoreApi<TState>>"
  const slice: UseBoundStore<StoreApi<TState>> = _create(createState as never);
  const initialState = slice.getState();

  resetters.push(() => {
    slice.setState(initialState, true);
  });

  return slice;
};

export const resetAllSlices = () => {
  for (const resetter of resetters) {
    resetter();
  }
};
