import { Box } from "@mui/material";
import { FormProvider, UseFormReturn, FieldValues } from "react-hook-form";

const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();
};

type FormProps<T extends FieldValues> = UseFormReturn<T> & {
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  children: React.ReactNode;
};

export const Form = <T extends FieldValues>({
  children,
  onSubmit = submitHandler,
  ...methods
}: FormProps<T>) => {
  return (
    <Box component="form" onSubmit={onSubmit}>
      <FormProvider {...methods}>{children}</FormProvider>
    </Box>
  );
};
