import loginImage from "@/assets/images/LoginImage.jpg";
import { ReactComponent as OtpLogo } from "@/assets/svg/otpLogo.svg";
import { ReactComponent as OtpLogoWhite } from "@/assets/svg/otpLogoWhite.svg";
import { LanguageDropDown, ModeToggleDropDown } from "@/components/layout";
import { Box, Grid, Paper, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

type LayoutProps = {
  children?: React.ReactNode;
};

export const Layout = ({ children }: LayoutProps) => {
  const theme = useTheme();

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${loginImage})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: theme.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "right",
            my: 2,
            mx: 4,
          }}
        >
          <ModeToggleDropDown />
          <LanguageDropDown />
        </Box>
        <Box
          sx={{
            my: 12,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Link to="/">
            <Box width={150} mb={3}>
              {theme.palette.mode === "light" ? <OtpLogo /> : <OtpLogoWhite />}
            </Box>
          </Link>
          <Typography component="h1" variant="h4" mb={3}>
            Partner Portal
          </Typography>
          {children}
        </Box>
      </Grid>
    </Grid>
  );
};
