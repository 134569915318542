import * as React from "react";
import { FieldValues, useFormContext, UseFormReturn } from "react-hook-form";

export const ConnectForm = ({
  children,
}: {
  children: (methods: UseFormReturn<FieldValues>) => React.ReactNode;
}) => {
  const methods = useFormContext();

  return <>{children({ ...methods })}</>;
};
