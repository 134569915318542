import { axios } from "@/lib/axios";
import { MutationConfig } from "@/lib/react-query";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

export type ChangePasswordDTO = {
  data: {
    password: string;
    confirmPassword: string;
  };
  resetId: string | undefined;
};

const changePassword = async ({
  data,
  resetId,
}: ChangePasswordDTO): Promise<void> => {
  if (!resetId) {
    return await axios.patch("/authentication/changepassword/", data);
  }
  return await axios.patch(`/authentication/changepassword/${resetId}`, data);
};

type UseChangePasswordOptions = {
  config?: MutationConfig<typeof changePassword>;
};

export const useChangePassword = ({
  config,
}: UseChangePasswordOptions = {}) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    onError: (error) => {
      const message = error.response?.data?.message || error.message;
      enqueueSnackbar(message, { variant: "error" });
    },
    onSuccess: () => {
      const message = t("auth.messages.passwordChanged");
      enqueueSnackbar(message);
    },
    mutationFn: changePassword,
    ...config,
  });
};
