import {
  Autocomplete,
  AutocompleteProps,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { AxiosError } from "axios";
import { Controller } from "react-hook-form";
import { UseQueryResult } from "react-query";
import { ConnectForm } from "./ConnectForm";

export type AutocompleteElementProps<T> = Omit<
  AutocompleteProps<T, undefined, undefined, undefined>,
  "name" | "renderInput" | "options" | "loading" | "onOpen"
> &
  Pick<
    TextFieldProps,
    "label" | "helperText" | "margin" | "autoFocus" | "required"
  > & {
    name: string;
    dataQuery: UseQueryResult<T[], AxiosError<any, any>>;
    dataIdKey: keyof T;
    dataLabelKey: keyof T | (keyof T)[];
  };

export const AutocompleteElement = <T extends Record<string, string | number>>({
  name,
  label,
  helperText,
  required,
  margin,
  autoFocus,
  dataQuery,
  dataIdKey,
  dataLabelKey,
  ...rest
}: AutocompleteElementProps<T>) => {
  return (
    <ConnectForm>
      {({ control }) => (
        <Controller
          name={name}
          control={control}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { invalid, error },
          }) => {
            return (
              <Autocomplete
                {...rest}
                size={rest.size ?? "small"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required={required}
                    size={rest.size ?? "small"}
                    error={invalid}
                    helperText={error ? error.message : helperText}
                    label={label}
                    value={value}
                  />
                )}
                value={value || null}
                onBlur={onBlur}
                onChange={(_, data) => {
                  onChange(data);
                }}
                isOptionEqualToValue={(option, selectedValue) =>
                  option[dataIdKey] === selectedValue[dataIdKey]
                }
                getOptionLabel={(option) =>
                  typeof dataLabelKey === "string"
                    ? option[dataLabelKey].toString()
                    : Array.isArray(dataLabelKey)
                    ? dataLabelKey.map((key) => option[key]).join(" ")
                    : ""
                }
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option[dataIdKey]}>
                      {Array.isArray(dataLabelKey)
                        ? dataLabelKey.map((key) => option[key]).join(" ")
                        : option[dataLabelKey]}
                    </li>
                  );
                }}
                onOpen={() => {
                  dataQuery.refetch();
                }}
                options={dataQuery.data ?? []}
                loading={dataQuery.isLoading}
              />
            );
          }}
        />
      )}
    </ConnectForm>
  );
};
