import * as React from "react";
import { Fallback } from "./components/misc";
import { AppProvider } from "./providers/app";
import { ModeProvider } from "./providers/mode";
import { AppRoutes } from "./routes";

const App = () => {
  return (
    <ModeProvider>
      <React.Suspense fallback={<Fallback />}>
        <AppProvider>
          <AppRoutes />
        </AppProvider>
      </React.Suspense>
    </ModeProvider>
  );
};

export default App;
