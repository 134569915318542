import { useTranslation } from "react-i18next";
import { Box, Stack, styled, Typography } from "@mui/material";

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: theme.palette.primary.main,
}));

export const NotAuthorized = () => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <Stack alignItems="center" spacing={2}>
        <StyledTypography variant="h1">403</StyledTypography>
        <Typography variant="h4" fontStyle="italic">
          {t("notAuthorized")}
        </Typography>
      </Stack>
    </Box>
  );
};
