import {
  format as formatDate,
  formatDistance,
  formatRelative,
  isDate,
} from "date-fns";
import enLocale from "date-fns/locale/en-US";
import srLocale from "date-fns/locale/sr-Latn";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { formats } from "./formats";

const locales: Record<string, Locale> = {
  "sr-Latn": srLocale,
  "en-US": enLocale,
};

const DETECTION_OPTIONS = {
  order: ["cookie"],
  caches: ["cookie"],
};

export default i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .use(ChainedBackend)
  .use(LanguageDetector)
  .init({
    supportedLngs: ["sr-Latn", "en-US"],
    detection: DETECTION_OPTIONS,
    debug: false,
    fallbackLng: "sr-Latn",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: (value, format, lng) => {
        if (isDate(value)) {
          if (format && lng) {
            const locale = locales[lng];

            if (format === "short") return formatDate(value, "P", { locale });
            if (format === "long") return formatDate(value, "PPPP", { locale });
            if (format === "full")
              return formatDate(value, "PPPppp", { locale });
            if (format === "relative")
              return formatRelative(value, new Date(), { locale });
            if (format === "ago")
              return formatDistance(value, new Date(), {
                locale,
                addSuffix: true,
              });

            return formatDate(value, format, { locale });
          } else {
            return formatDate(
              value,
              lng ? formats.dateFormats[lng] : formats.dateFormats["en-US"]
            );
          }
        } else {
          throw Error("Value is not a date");
        }
      },
    },
    backend: {
      backends: [HttpBackend],
      backendOptions: [
        {
          loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
      ],
    },
  });
