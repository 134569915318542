import { queryClient } from "@/lib/react-query";
import { AuthProvider } from "@/providers/auth";
import getDesignTokens from "@/theme";
import "@/utils/i18n";
import { SnackbarUtilsConfigurator } from "@/utils/snackbarUtilsConfigurator";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import { Box, Button, CssBaseline, Stack, Typography } from "@mui/material";
import { enUS, Localization, srRS } from "@mui/material/locale";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import { ConfirmOptions, ConfirmProvider } from "material-ui-confirm";
import { SnackbarKey, SnackbarProvider, useSnackbar } from "notistack";
import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";
import { useMode } from "./mode";

const ErrorFallback = () => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Stack alignItems="center" spacing={2}>
        <Typography variant="h4" fontStyle="italic">
          {t("somethingWentWrong")}
        </Typography>
        <Button
          startIcon={<RefreshRoundedIcon />}
          onClick={() => window.location.assign(window.location.origin)}
        >
          {t("refresh")}
        </Button>
      </Stack>
    </Box>
  );
};

const SnackbarAction = (key: SnackbarKey) => {
  const { closeSnackbar } = useSnackbar();

  const { t } = useTranslation();

  return (
    <Button color="inherit" onClick={() => closeSnackbar(key)}>
      {t("messages.dismiss")}
    </Button>
  );
};

const locales: Record<string, Localization> = {
  "en-US": enUS,
  "sr-Latn": srRS,
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const { t, i18n } = useTranslation();

  const currentLang = locales[i18n.language];

  const { mode } = useMode();

  const theme = React.useMemo(
    () => createTheme(getDesignTokens(mode), currentLang),
    [mode, currentLang]
  );

  const themeWithResponsiveFontSize = responsiveFontSizes(theme);

  const confirmDefaultOptions = React.useMemo<ConfirmOptions>(
    () => ({
      confirmationButtonProps: { autoFocus: true },
      cancellationButtonProps: { color: "inherit" },
      confirmationText: t("dialogs.yes"),
      cancellationText: t("dialogs.no"),
    }),
    [t]
  );

  return (
    <ThemeProvider theme={themeWithResponsiveFontSize}>
      <CssBaseline enableColorScheme />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV !== "test" && <ReactQueryDevtools />}
            <SnackbarProvider
              anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
              action={SnackbarAction}
            >
              <ConfirmProvider defaultOptions={confirmDefaultOptions}>
                <SnackbarUtilsConfigurator />
                <AuthProvider>
                  <Router>{children}</Router>
                </AuthProvider>
              </ConfirmProvider>
            </SnackbarProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};
