import { Box } from "@mui/material";
import * as React from "react";
import { Header } from "./Header";
import { Navigator } from "./Navigator";

type MainLayoutProps = {
  children: React.ReactNode;
};

const drawerWidth = 256;

export const MainLayout = ({ children }: MainLayoutProps) => {
  const [navOpen, setNavOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setNavOpen(!navOpen);
  };

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <Navigator
        PaperProps={{ style: { width: drawerWidth } }}
        variant="temporary"
        open={navOpen}
        onClose={handleDrawerToggle}
      />
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <Header onDrawerToggle={handleDrawerToggle} />
        <Box
          component="main"
          sx={{
            flex: 1,
            padding: 2,
            backgroundColor: "background.default",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
