import { useMode } from "@/providers/mode";
import {
  CircularProgressProps,
  Box,
  CircularProgress,
  circularProgressClasses,
} from "@mui/material";

export const Spinner = (props: CircularProgressProps) => {
  const { mode } = useMode();

  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.grey[mode === "light" ? 200 : 800],
        }}
        size={props.size ?? 100}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: props.color
            ? props.color
            : mode === "light"
            ? "#52ae30"
            : "white",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={props.size ?? 100}
        thickness={4}
        {...props}
      />
    </Box>
  );
};
