import { TextField, TextFieldProps } from "@mui/material";
import { Controller } from "react-hook-form";
import { PatternFormat, PatternFormatProps } from "react-number-format";
import { ConnectForm } from "./ConnectForm";

export type PatternElementProps = Omit<
  PatternFormatProps<TextFieldProps>,
  "name"
> & {
  name: string;
};

export const PatternElement = ({
  type,
  name,
  ...rest
}: PatternElementProps) => {
  return (
    <ConnectForm>
      {({ control }) => (
        <Controller
          name={name}
          control={control}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { invalid, error },
          }) => {
            return (
              <PatternFormat
                {...rest}
                name={name}
                size={rest.size ?? "small"}
                displayType="input"
                customInput={TextField}
                value={value || ""}
                onValueChange={(values) => {
                  onChange(values.value ? values.value : "");
                }}
                onBlur={onBlur}
                type="text"
                helperText={error ? error.message : rest.helperText}
                error={invalid}
              />
            );
          }}
        />
      )}
    </ConnectForm>
  );
};
