import { Form, PasswordElement, useForm } from "@/components/form";
import { LoadingButton } from "@mui/lab";
import { TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as z from "zod";
import { ChangePasswordDTO, useChangePassword } from "../api/changePassword";

const defaultValues = {
  username: "",
  password: "",
  confirmPassword: "",
};

export const ChangePassword = () => {
  const { state } = useLocation() as {
    state: { firstSignIn: boolean } | undefined;
  };

  const { t } = useTranslation();

  const { resetId } = useParams();

  const navigate = useNavigate();

  //USERNAME DODAT SAMO ZBOG CHROMIUM DOKUMENTACIJE, VIDI: https://goo.gl/9p2vKq
  const schema = z
    .object({
      password: z
        .string()
        .regex(
          new RegExp(".*[A-Z].*"),
          t("form.validation.password.uppercaseCharacter")
        )
        .regex(
          new RegExp(".*[a-z].*"),
          t("form.validation.password.lowercaseCharacter")
        )
        .regex(new RegExp(".*\\d.*"), t("form.validation.password.oneNumber"))
        .regex(
          new RegExp(".*[`~<>?,./!@#$%^&*()\\-_+=\"'|{}\\[\\];:\\\\].*"),
          t("form.validation.password.oneSpecialCharacter")
        )
        .min(8, t("form.validation.minLength", { min: 8 })),
      confirmPassword: z.string(),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: t("form.validation.password.passwordsMatch"),
      path: ["confirmPassword"],
    });

  const changePasswordMutation = useChangePassword();

  const onSubmit = (values: ChangePasswordDTO["data"]) => {
    changePasswordMutation.mutate(
      { data: values, resetId },
      {
        onSuccess: () => {
          navigate("/");
        },
      }
    );
  };

  const methods = useForm<ChangePasswordDTO["data"], typeof schema>({
    options: { defaultValues },
    schema,
  });

  const { handleSubmit } = methods;

  return (
    <Form<ChangePasswordDTO["data"]>
      {...methods}
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextField
        name="username"
        autoComplete="username"
        label={t("auth.username")}
        sx={{ display: "none" }}
      />
      <PasswordElement
        name="password"
        autoComplete="new-password"
        label={t("auth.newPassword")}
        margin="normal"
        size="medium"
        required
      />
      <PasswordElement
        name="confirmPassword"
        autoComplete="new-password"
        label={t("auth.confirmPassword")}
        margin="normal"
        size="medium"
        required
      />
      {state?.firstSignIn ? (
        <Typography variant="caption" color="text.secondary" component="div">
          {t("auth.changeInitialPasswordHelperText")}
        </Typography>
      ) : null}
      <LoadingButton
        loading={changePasswordMutation.isLoading}
        type="submit"
        variant="contained"
        fullWidth
        sx={{
          mt: 2,
        }}
      >
        {state?.firstSignIn
          ? t("auth.changeInitialPassword")
          : t("auth.changePassword")}
      </LoadingButton>
    </Form>
  );
};
