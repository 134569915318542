import axios, { AxiosError } from "axios";
import SnackbarUtils from "@/utils/snackbarUtilsConfigurator";
import {
  QueryClient,
  DefaultOptions,
  UseQueryOptions,
  UseMutationOptions,
  QueryCache,
} from "react-query";

const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: (error) => {
      if (axios.isAxiosError(error) && error.response) {
        if (error.response.status >= 500) return true;
      }
      return false;
    },
    retry: false,
    refetchOnWindowFocus: false,
  },
};

export const queryClient = new QueryClient({
  defaultOptions: queryConfig,
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query.state.data !== undefined && axios.isAxiosError(error)) {
        const message = error.response?.data?.message || error.message;
        SnackbarUtils.error(message);
      }
    },
  }),
});

export type ExtractFnReturnType<FnType extends (...args: any) => any> = Awaited<
  ReturnType<FnType>
>;

export type QueryConfig<
  QueryFnType extends (...args: any) => any,
  SelectedData = ExtractFnReturnType<QueryFnType>
> = Omit<
  UseQueryOptions<ExtractFnReturnType<QueryFnType>, AxiosError, SelectedData>,
  "queryKey" | "queryFn"
>;

export type MutationConfig<MutationFnType extends (...args: any) => any> =
  UseMutationOptions<
    ExtractFnReturnType<MutationFnType>,
    AxiosError,
    Parameters<MutationFnType>[0]
  >;
