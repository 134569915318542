import { axios } from "@/lib/axios";
import { LoginResponse } from "../types";

export type LoginCredentialsDTO = {
  username: string;
  password: string;
};

export const loginWithUsernameAndPassword = async (
  data: LoginCredentialsDTO
): Promise<LoginResponse> => {
  return await axios.post("/authentication/login", data, {
    withCredentials: true,
  });
};
