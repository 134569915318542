import { axios } from "@/lib/axios";
import { MutationConfig } from "@/lib/react-query";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

const forgotPassword = async ({
  username,
}: {
  username: string;
}): Promise<void> => {
  return await axios.post("/authentication/forgotpassword", { username });
};

type UseForgotPasswordOptions = {
  config?: MutationConfig<typeof forgotPassword>;
};

export const useForgotPassword = ({
  config,
}: UseForgotPasswordOptions = {}) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    onSuccess: () => {
      const message = t("auth.messages.emailSent");
      enqueueSnackbar(message);
    },
    onError: (error) => {
      const message = error.response?.data?.message || error.message;
      enqueueSnackbar(message, { variant: "error" });
    },
    mutationFn: forgotPassword,
    ...config,
  });
};
