import storage from "@/utils/storage";
import { PaletteMode, useMediaQuery } from "@mui/material";
import * as React from "react";

interface ModeContextInterface {
  toggleMode: () => void;
  mode: PaletteMode;
}

const ModeContext = React.createContext<ModeContextInterface | null>(null);

export const ModeProvider = ({ children }: { children: React.ReactNode }) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const [mode, setMode] = React.useState<PaletteMode>("light");

  React.useEffect(() => {
    const existingPreference = storage.getMode();
    if (existingPreference) {
      existingPreference === "light" ? setMode("light") : setMode("dark");
    } else {
      const preferredMode = prefersDarkMode ? "dark" : "light";
      setMode(preferredMode);
      storage.setMode(preferredMode);
    }
  }, [prefersDarkMode]);

  const colorMode: ModeContextInterface = React.useMemo(
    () => ({
      toggleMode: () => {
        storage.setMode(mode === "light" ? "dark" : "light");
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
      mode,
    }),
    [mode]
  );

  return (
    <ModeContext.Provider value={colorMode}>{children}</ModeContext.Provider>
  );
};

export const useMode = () => {
  const context = React.useContext(ModeContext);
  if (!context) {
    throw new Error("useMode must be used within a ModeProvider");
  }

  const { mode, toggleMode } = context;

  return { mode, toggleMode };
};
