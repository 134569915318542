import { TextField, TextFieldProps } from "@mui/material";
import { Controller } from "react-hook-form";
import { ConnectForm } from "./ConnectForm";

export type TextFieldElementProps = Omit<TextFieldProps, "name"> & {
  name: string;
};

export const TextFieldElement = ({
  type,
  name,
  ...rest
}: TextFieldElementProps) => {
  return (
    <ConnectForm>
      {({ control }) => (
        <Controller
          name={name}
          control={control}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { invalid, error },
          }) => {
            return (
              <TextField
                {...rest}
                size={rest.size ?? "small"}
                name={name}
                value={value || ""}
                onChange={onChange}
                onBlur={onBlur}
                type={type}
                error={invalid}
                helperText={error ? error.message : rest.helperText}
              />
            );
          }}
        />
      )}
    </ConnectForm>
  );
};
