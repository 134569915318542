import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as React from "react";

export const LanguageDropDown = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { i18n, t } = useTranslation();

  const setLanguage = (language: string) => {
    if (language !== i18n.language) {
      i18n.changeLanguage(language);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title={<>{t("dropdownNavigation.language")}</>}>
          <IconButton
            onClick={handleClick}
            aria-controls={open ? "language-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            color="inherit"
            size="large"
          >
            <LanguageRoundedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        transitionDuration={2}
        anchorEl={anchorEl}
        id="language-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              mr: 1,
            },
            "&:before": {
              content: "''",
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          disabled={i18n.language === "sr-Latn"}
          onClick={() => setLanguage("sr-Latn")}
        >
          {t("languages.serbian")}
        </MenuItem>
        <MenuItem
          disabled={i18n.language === "en-US"}
          onClick={() => setLanguage("en-US")}
        >
          {t("languages.english")}
        </MenuItem>
      </Menu>
    </>
  );
};
