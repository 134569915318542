import { Activity, Process } from "@/lib/authorization";

export type TokenResponse = { token: string };

export type FirstLoginResponse = { resetId: string };

export type LoginResponse = TokenResponse | FirstLoginResponse;

export enum ROLES {
  ADMIN = "administratori",
  EMPLOYEE = "radnici",
  PARTNER = "partneri",
  AGENT = "agenti",
  CONTRACTAGENT = "agentugovor", //agent po statusu ugovora
  SINGLE = "pojedinacno", //pojedinacan partner
}

export type RolesValues = `${ROLES}`;

export type MeResponse = {
  name: string;
  userId: number;
  roles: RolesValues[];
  details: {
    name: string;
    branches: string[];
    partner: string | null;
  };
};

export type MeAuthorizationResponse = Record<Process, Activity[]>;

export type AuthUser = MeResponse & {
  permissions: MeAuthorizationResponse;
};

export type UserAuthorizationResponse = {
  activityId: number;
  name: string;
  inherited: boolean;
  permission: boolean;
  deny: boolean;
};

export type AuthorizationResponse = {
  processId: number;
  name: string;
};
